// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import Product from "@src/views/products/store/reducer";
import shop from "@src/views/shops/store/reducer";
import delivery from "@src/views/delivery/store/reducer";
import planPackage from "@src/views/plan-package/store/reducer";
import user from "@src/views/users/store/reducer";

const rootReducer = combineReducers({ 
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,

  Product,
  shop,
  delivery,
  planPackage,
  user,
});

export default rootReducer;
