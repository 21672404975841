// ** Initial State
const initialState = {
    data: [],
    total: 1,
  }
  
  const DeliveryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DELIVERY_LIST':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
        }
      default:
        return state
    }
  }
  
  export default DeliveryReducer
  